export const REQUESTED_ARTICLE = 'REQUESTED_ARTICLE';
export const RECIEVED_ARTICLE = 'RECIEVED_ARTICLE';
export const ERROR_GETTING_ARTICLE = 'ERROR_GETTING_ARTICLE';

export const REQUESTED_ARTICLES = 'REQUESTED_ARTICLES';
export const RECIEVED_ARTICLES = 'RECIEVED_ARTICLES';

export const ADDED_ARTICLE = 'ADDED_ARTICLE';
export const DELETED_ARTICLE = 'DELETED_ARTICLE';
export const ERROR_ADDING_ARTICLE = 'ERROR_ADDING_ARTICLE';
export const ERROR_DELETING_ARTICLE = 'ERROR_DELETING_ARTICLE';

export const EDITED_ARTICLE = 'EDITED_ARTICLE';
export const ERROR_EDITING_ARTICLE = 'ERROR_EDITING_ARTICLE';

export const AUTH_REQUESTED = 'AUTH_REQUESTED';
export const AUTH_RECEIVED = 'AUTH_RECEIVED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_INVALID = 'AUTH_INVALID';

export const LIKED_ARTICLE = 'LIKED_ARTICLE';
export const VIEWED_ARTICLE = 'VIEWED_ARTICLE';
export const PUBLISH_ARTICLE = 'PUBLISH_ARTICLE';
export const UNPUBLISH_ARTICLE = 'UNPUBLISH_ARTICLE';
